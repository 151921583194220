import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        starting: false,
        twilioToken: null,
        room: {},
        doctor: {},
        doctorLoading: false,
        doctorError: null,
        patient: {},
        oldPatient: {},
        payment: {},
        notes: [],
        prescriptions: []
    }
}

// initial state
const state = getDefaultState

// getters
const getters = {
    getField
}

// actions
const actions = {
    resetState ({ commit }) {
        commit('resetState')
    },

    setTwilioToken ({ commit }, payload) {
        commit('setTwilioToken', payload)
    },

    setStarting ({ commit }, payload) {
        commit('setStarting', payload)
    },

    // Room
    setRoom ({ commit }, payload) {
        commit('setPatient', payload)
        commit('setNotes', payload.notes)
        commit('setPrescriptions', payload.prescriptions)
        commit('setPayment', payload.payment)
        delete payload.patient
        delete payload.caller
        commit('setRoom', payload)
    },

    // Doctor
    setDoctor ({ commit }, payload) {
        commit('setDoctorLoading', true)

        axios.post(`/doctors/${payload}/notify-patient-assigned`)

        return new Promise((resolve, reject) => {
            axios.get('/doctors/' + payload)
                .then((response) => {
                    commit('setDoctor', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('setDoctorError', error.response.data.message)
                    reject(error)
                })
                .finally(() => {
                    commit('setDoctorLoading', false)
                })
        })
    },

    unsetDoctor ({ commit }) {
        commit('setDoctorLoading', true)
        commit('setDoctor', {})
        commit('setDoctorLoading', false)
    },

    // Patient
    setPatient ({ commit }, payload) {
        commit('setPatient', payload)
    },

    unsetPatient ({ commit }) {
        commit('unsetPatient')
    },

    discardPatient ({ commit }) {
        commit('discardPatient')
    },

    updatePatient ({ commit }, payload) {
        commit('updatePatient', payload)
    },

    // Reason
    setReasons ({ commit }, payload) {
        commit('setReasons', payload)
    },

    // Notes
    addNote ({ commit }, payload) {
        commit('addNote', payload)
    },

    removeNote ({ commit }, payload) {
        commit('removeNote', payload)
    },

    updateNote ({ commit }, payload) {
        commit('updateNote', payload)
    },

    // Prescriptions
    addPrescription ({ commit }, payload) {
        commit('addPrescription', payload)
    },

    removePrescription ({ commit }, payload) {
        commit('removePrescription', payload)
    },

    updatePrescription ({ commit }, payload) {
        commit('updatePrescription', payload)
    },

    // Payment
    setPayment ({ commit }, payload) {
        commit('setPayment', payload)
    },

    removePayment ({ commit }, payload) {
        commit('removePayment', payload)
    },

    updatePayment ({ commit }, payload) {
        commit('updatePayment', payload)
    }
}

// mutations
const mutations = {
    updateField,

    resetState (state) {
        Object.assign(state, getDefaultState())
    },

    setTwilioToken (state, payload) {
        state.twilioToken = payload
    },

    setStarting (state, payload) {
        state.starting = payload
    },

    // Room
    setRoom (state, payload) {
        // state.room = { ..._.mapKeys(payload, (v, k) => _.camelCase(k)) }
        state.room = { ...payload }
    },

    // Reason
    setReasons (state, payload) {
        // state.room = { ..._.mapKeys(payload, (v, k) => _.camelCase(k)) }
        state.room.reasons = [...payload.reasons]
    },

    // Doctor
    setDoctor (state, payload) {
        state.doctor = payload
    },

    setDoctorLoading (state, payload) {
        state.doctorLoading = payload
    },

    setDoctorError (state, payload) {
        state.doctorError = payload
    },

    // Patient
    setPatient (state, payload) {
        // state.patient = { ..._.mapKeys(payload, (v, k) => _.camelCase(k)) }
        if (payload.patient?.id) {
            state.patient = payload.patient
        } else {
            state.patient = {
                doc_number: payload.hints.doc_number,
                email: payload.hints.email
            }
        }
    },

    updatePatient (state, payload) {
        state.patient = payload
    },

    discardPatient (state) {
        state.patient = state.oldPatient
    },

    unsetPatient (state) {
        state.oldPatient = state.patient
        state.patient = {
            doc_number: state.room.hints.doc_number,
            email: state.room.hints.email
        }
    },

    // Payment
    setPayment (state, payload) {
        state.payment = payload
    },

    updatePayment (state, payload) {
        state.payment = payload
    },

    removePayment (state) {
        state.payment = null
    },

    // Notes
    setNotes (state, payload) {
        state.notes = [...payload]
    },

    addNote (state, payload) {
        state.notes.push({ ...payload })
    },

    updateNote (state, payload) {
        const index = state.notes.findIndex(x => x.id === payload.id)
        if (index !== -1) {
            state.notes = [...state.notes.slice(0, index), { ...payload }, ...state.notes.slice(index + 1)]
        }
    },

    removeNote (state, payload) {
        state.notes = state.notes.filter(note => note.id !== payload.id)
    },

    // Notes
    setPrescriptions (state, payload) {
        state.prescriptions = [...payload]
    },

    // Prescriptions
    addPrescription (state, payload) {
        state.prescriptions.push({ ...payload })
    },

    updatePrescription (state, payload) {
        const index = state.prescriptions.findIndex(x => x.id === payload.id)
        if (index !== -1) {
            state.prescriptions = [...state.prescriptions.slice(0, index), { ...payload }, ...state.prescriptions.slice(index + 1)]
        }
    },

    removePrescription (state, payload) {
        state.prescriptions = state.prescriptions.filter(prescription => prescription.id !== payload.id)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
