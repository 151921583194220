<template>
    <div>
        <notifications group="error" :duration="-1" position="top right" classes="vue-notification bg-danger text-white" />
        <notifications group="success" :duration="2000" position="top right" classes="vue-notification bg-primary text-white" />
        <notifications group="info" :duration="2000" position="top right" classes="vue-notification bg-info text-white" />
        <notifications group="warning" :duration="5000" position="top right" classes="vue-notification bg-warning text-dark" />
        <notifications group="default" :duration="2000" position="top right" classes="vue-notification bg-white text-dark" />
    </div>
</template>

<script>
export default {
    name: 'CustomNotifications'
}
</script>
