<template>
    <div class="d-flex justify-content-center">
        <i v-if="!loaded && !error" class="fas fa-circle-notch fa-spin text-white fa-2x align-self-center"></i>
        <img v-if="!error" :src="image" @load="loaded = true" @error="error = true">
        <!--img v-else :src="publicUrl + 'img/logo.png'" :class="{'d-none': !loaded}" @load="loaded = true" class="p-3"-->
    </div>
</template>

<style scoped>
    img {
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>

<script>
export default {
    name: 'image-wrapper-component',

    props: [
        'image'
    ],

    data: () => ({
        loaded: false,
        error: false
    }),

    watch: {
        image: function () {
            this.loaded = false
            this.error = false
        }
    }

}
</script>
