import Vue from 'vue'
import Vuex from 'vuex'
import call from './modules/call'
import rooms from './modules/rooms'
import users from './modules/users'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        rooms,
        call,
        users
    },
    strict: debug
})
