const getDefaultState = () => {
    return {
        online: []
    }
}

// initial state
const state = getDefaultState

// getters
const getters = {
    onlineCount: state => {
        return state.online.length
    },

    onlineIds: state => {
        return state.online.map(user => user.id)
    }
}

// actions
const actions = {
    set ({ commit }, payload) {
        commit('set', payload)
    },

    add ({ commit }, payload) {
        commit('add', payload)
    },

    remove ({ commit }, payload) {
        commit('remove', payload)
    },

    fetchOnline ({ commit }) {
        axios.get('/users/fetch-online')
            .then((response) => {
                commit('set', response.data.data)
            })
    }
}

// mutations
const mutations = {
    set (state, payload) {
        state.online = payload
    },

    add (state, payload) {
        state.online.push({ ...payload })
    },

    remove (state, payload) {
        state.online = state.online.filter(user => { return user.id !== payload.id })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
