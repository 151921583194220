import Vue from 'vue'

const getDefaultState = () => {
    return {
        resources: [],
        loading: false,
        error: null
    }
}

// initial state
const state = getDefaultState

// getters
const getters = {
    allRooms: state => {
        return _.sortBy(state.resources, 'status')
    },

    incomingRooms: state => {
        if (Vue.auth.user()) {
            if (Vue.auth.check('doctor', 'roles')) {
                return state.resources.filter(room => room.caller?.id === Vue.auth.user()?.id && ['derived'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
            } else {
                return state.resources.filter(room => !room.caller && ['created'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
            }
        } else {
            return []
        }
    },

    secretaryRooms: state => {
        return state.resources.filter(room => ['taken', 'started'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
    },

    doctorsRooms: state => {
        return state.resources.filter(room => ['derived', 'attended'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
    },

    paymentRooms: state => {
        if (Vue.auth.user()) {
            if (Vue.auth.check('doctor', 'roles')) {
                return state.resources.filter(room => room.caller?.id === Vue.auth.user()?.id && ['pending'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
            } else {
                return state.resources.filter(room => ['pending'].includes(room.status)).sort(function (a, b) { return b.priority - a.priority })
            }
        } else {
            return []
        }
    }
}

// actions
const actions = {
    async fetch ({ commit }, payload) {
        commit('setLoading', true)
        await axios.get('/rooms', {
            params: payload
        })
            .then((response) => {
                commit('set', response.data.data)
            })
            .catch((e) => {
                commit('setError', axios.isCancel(e) ? e.message : e.response)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    add ({ commit }, payload) {
        commit('add', payload)
    },

    remove ({ commit }, resource_id) {
        commit('remove', resource_id)
    },

    update ({ commit }, payload) {
        commit('update', payload)
    },

    resetState ({ commit }) {
        commit('resetState')
    },

    updateStatus ({ commit }, payload) {
        commit('updateStatus', payload)
    }
}

// mutations
const mutations = {
    resetState (state) {
        Object.assign(state, getDefaultState())
    },

    set (state, payload) {
        state.resources = payload
    },

    add (state, payload) {
        state.resources.push({ ...payload })
    },

    remove (state, resource_id) {
        state.resources = state.resources.filter(room => { return room.id !== resource_id })
    },

    update (state, payload) {
        const index = state.resources.findIndex(x => x.id === payload.id)
        if (index !== -1) {
            state.resources = [...state.resources.slice(0, index), { ...payload }, ...state.resources.slice(index + 1)]
        } else {
            if (Vue.auth.check('doctor', 'roles') && ['derived', 'pending'].includes(payload.status)) {
                state.resources.push({ ...payload })
            }
        }
    },

    setLoading (state, payload) {
        state.loading = payload
    },

    setError (state, payload) {
        state.error = payload
    },

    updateStatus (state, payload) {
        const index = state.resources.findIndex(x => x.id === payload.room.id)
        if (index !== -1) {
            payload.room.payment.status = payload.status
            state.resources = [...state.resources.slice(0, index), { ...payload.room }, ...state.resources.slice(index + 1)]
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
