<template>
    <div class="layout-wrapper layout-2">
        <nprogress-container></nprogress-container>
        <CustomNotifications />

        <div class="layout-inner">
            <app-layout-sidenav />

            <div class="layout-container">
                <app-layout-navbar />

                <div class="layout-content">
                    <div class="router-transitions container-fluid flex-grow-1 container-p-y">
                        <Version />
                        <Logout />

                        <!-- Floating video -->
                        <div v-show="!$root.showFixedVideo && room.id && room.host_installation_id === $auth.user().installation_id"
                            class="floating-video-container position-fixed">
                            <b-card no-body class="shadow-lg">
                                <b-card-header
                                    class="p-2 cursor-pointer"
                                    v-b-toggle.accordion-1>
                                        <span v-if="room.id" class="text-success"><i class="fas fa-exclamation-circle"></i> {{ $t('label.call_in_progress') }}</span>
                                        <span v-else>{{ $t('label.video_streaming') }}</span>

                                    <i class="fas fa-chevron-up float-right mt-1"></i>
                                    <i class="fas fa-chevron-down float-right mt-1"></i>
                                </b-card-header>
                                <b-collapse id="accordion-1" :visible="!collapsed" accordion="accordion">
                                    <b-card-body class="p-0">
                                        <div id="floating-video-wrapper">
                                            <Video id="video-component" />
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                        <!-- / Floating video -->

                        <keep-alive :include="['LayoutCall','Video']">
                            <router-view/>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-overlay" @click="closeSidenav"></div>
    </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'

import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import CustomNotifications from '@/components/partials/CustomNotifications'
import Version from '@/components/partials/Version'
import Logout from '@/components/partials/Logout'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import Video from '@/components/call/Video'
import { mapState } from 'vuex'

export default {
    name: 'app-layout-2',
    components: {
        'app-layout-navbar': LayoutNavbar,
        'app-layout-sidenav': LayoutSidenav,
        NprogressContainer,
        CustomNotifications,
        Logout,
        Version,
        Video
    },

    data: () => ({
        echo: null,
        isConnecting: false,
        collapsed: true,
        resenceChannel: null
    }),

    mounted () {
        this.layoutHelpers.init()
        this.layoutHelpers.update()
        this.layoutHelpers.setAutoUpdate(true)

        window.addEventListener('online', () => this.checkStatus())

        this.$auth
            .load()
            .then(async () => {
                if (this.$auth.check()) {
                    await this.connect()
                    this.subscribeChannels()
                    this.checkStatus()
                    this.$store.dispatch('rooms/fetch')
                }
            })
    },

    beforeDestroy () {
        this.layoutHelpers.destroy()
    },

    computed: {
        ...mapState('call', {
            room: 'room'
        }),

        isConnected: {
            cache: false,
            get () {
                return (this.echo && this.echo.connector.pusher.connection.connection !== null)
            }
        }
    },

    methods: {
        closeSidenav () {
            this.layoutHelpers.setCollapsed(true)
        },

        connect () {
            return new Promise((resolve, reject) => {
                this.isConnecting = true

                window.Pusher = Pusher
                Pusher.logToConsole = false

                this.echo = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.VUE_APP_PUSHER_KEY,
                    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                    authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + this.$auth.token()
                        }
                    }
                })

                this.echo.connector.pusher.connection.bind('connected', (event) => {
                    this.isConnecting = false
                    return resolve(event)
                })

                this.echo.connector.pusher.connection.bind('error', (error) => {
                    this.isConnecting = false
                    return reject(error)
                })
            })
        },

        disconnect () {
            if (!this.echo) return
            this.echo.disconnect()
        },

        subscribeChannels () {
            const socketId = this.echo.connector.pusher.connection.socket_id
            if (socketId) {
                this.echo.private(`User.${this.$auth.user().id}`)
                    .listen('.updated', () => {
                        // console.log('.updated')
                        this.$auth.fetch() // Re-fetch my user data
                    })
                    .listen('.host-changed', (payload) => {
                        // console.log('.host-changed')
                        this.checkStatus()
                    })
                    .listen('.role-updated', () => {
                        // console.log('.role-updated')
                        this.$auth.fetch() // Re-fetch my user data
                    })
                    .listen('.room.started', async (payload) => {
                        // console.log('.room.started')
                        await this.checkStatus()
                    })
                    .listen('.room.unstarted', async (payload) => {
                        // console.log('.room.unstarted')
                        await this.checkStatus()
                    })
                    .listen('.patient-assigned', async (payload) => {
                        // console.log('.assigned-patient')
                        this.$notify({
                            group: 'info',
                            duration: 10000,
                            title: this.$t('label.patient_assigned'),
                            text: this.$t('label.patient_assigned_desc')
                        })
                    })

                this.presenceChannel = this.echo.join('rooms')

                if (this.$auth.check('call_view_new')) {
                    this.presenceChannel
                        // Room events
                        .listen('.created', (payload) => {
                            // console.log('.created')
                            this.$store.dispatch('rooms/add', { ...payload.room })

                            this.playSound()
                            this.$notify({
                                group: 'info',
                                duration: 10000,
                                title: this.$t('label.incoming_call'),
                                text: this.$t('label.new_incoming_call')
                            })

                            if (document.hidden) { this.showPushNotification({ ...payload.room }) }
                        })
                }

                if (this.$auth.check('call_view_secretary')) {
                    this.presenceChannel
                        .listen('.taken', async (payload) => {
                            // console.log('.taken')

                            if (payload.user_id === this.$auth.user().id) {
                                await this.checkStatus()
                                // this.$router.push({ name: 'call' })
                            } else {
                                this.$store.dispatch('rooms/update', { ...payload.room })
                            }
                        })
                }

                if (this.$auth.check('call_view_payment')) {
                    this.presenceChannel
                        .listen('.payment', (payload) => {
                            // console.log('.room.payment')

                            this.$store.dispatch('rooms/update', { ...payload.room })
                            if (this.room.id === payload.room.id) {
                                this.$router.push({ name: 'rooms', params: { mustResetState: true } })
                            }
                        })
                }

                this.presenceChannel
                    // Room events
                    .listen('.derived', (payload) => {
                        // console.log('.derived')

                        this.$store.dispatch('rooms/update', { ...payload.room })

                        if (this.room.id === payload.room.id) {
                            this.$router.push({ name: 'rooms', params: { mustResetState: true } })
                        }

                        if (payload.room.caller.id === this.$auth.user().id) {
                            if (!this.room.id) { this.playSound() }

                            if (document.hidden) {
                                this.showPushNotification({ ...payload.room })
                            } else {
                                this.$notify({
                                    group: 'info',
                                    duration: 10000,
                                    title: this.$t('label.incoming_call'),
                                    text: this.$t('label.new_incoming_call')
                                })
                            }
                        }
                    })
                    .listen('.payment-canceled', (payload) => {
                        // console.log('.payment-canceled')

                        this.$store.dispatch('rooms/update', { ...payload.room })
                    })
                    .listen('.rejected', (payload) => {
                        // console.log('.rejected')

                        this.$store.dispatch('rooms/update', { ...payload.room })
                        if (this.room.id === payload.room.id) {
                            this.$router.push({ name: 'rooms', params: { mustResetState: true, showRejectedModal: this.$auth.user().id !== payload.user_id } })
                        } else {
                            if (this.$auth.check('call_view_new')) {
                                if (!this.room.id) { this.playSound() }
                                this.$notify({
                                    group: 'info',
                                    duration: 10000,
                                    title: this.$t('label.incoming_call'),
                                    text: this.$t('label.new_incoming_call')
                                })
                            }
                        }
                    })
                    .listen('.finished', (payload) => {
                        // console.log('.finished')

                        this.$store.dispatch('rooms/remove', payload.room.id)
                        if (this.room.id === payload.room.id) {
                            this.$router.push({ name: 'rooms', params: { mustResetState: true, showFinishedModal: this.$auth.user().id !== payload.user_id } })
                        }
                    })
                    .listen('.canceled', (payload) => {
                        // console.log('.canceled')

                        this.$store.dispatch('rooms/remove', payload.room.id)
                        if (this.room.id === payload.room.id) {
                            this.$router.push({ name: 'rooms', params: { mustResetState: true, showCanceledModal: true } })
                        }
                    })
                    .listen('.takenover', (payload) => {
                        // console.log('.takenover')
                        // console.log(payload)

                        if (payload.user_id === this.$auth.user().id) {
                            this.checkStatus()
                        } else {
                            this.$store.dispatch('rooms/update', { ...payload.room })
                            if (this.room.id === payload.room.id) {
                                this.$router.push({ name: 'rooms', params: { mustResetState: true, showTakenoverModal: this.$auth.user().id !== payload.user_id } })
                            }
                        }
                    })
                    // Twilio events
                    .listen('.participant-connected', (room) => {
                        // console.log('.participant-connected')
                        this.$store.dispatch('rooms/update', room)
                    })
                    .listen('.participant-disconnected', (room) => {
                        // console.log('.participant-disconnected')
                        this.$store.dispatch('rooms/update', room)
                    })
                    // User events
                    .listen('.user.online', (user) => {
                        this.$store.dispatch('users/add', user)
                    })
                    .listen('.user.offline', (user) => {
                        // this.$store.dispatch('rooms/fetch')
                        this.$store.dispatch('users/remove', user)
                    })
                    .listen('.user.logout', (user) => {
                        // this.$store.dispatch('rooms/fetch')
                        this.$store.dispatch('users/remove', user)
                    })
            } else {
                alert('Error on WS channel')
            }
        },

        checkStatus () {
            axios.patch('/users/check-status', {
                websocket_token: this.echo.connector?.pusher?.connection?.socket_id
            })
                .then((response) => {
                    const room = response.data.room
                    const twilio_token = response.data.twilio_token

                    if (room) {
                        this.$store.dispatch('call/setRoom', room)
                        this.$store.dispatch('call/setTwilioToken', twilio_token)
                        if (this.$route.name === 'rooms') { this.$router.push({ name: 'call' }) }
                    } else if (this.$route.name === 'call') {
                        this.$router.push({ name: 'rooms' })
                    }
                })
                .finally(() => {
                    this.$root.$emit('removeSplashScreen')
                })
        },

        showPushNotification (room) {
            const notification = new Notification(this.$t('label.incoming_call'), {
                body: this.$t('label.new_incoming_call'),
                icon: `${this.publicUrl}/img/notification-logo.png`
            })

            notification.addEventListener('click', function (e) {
                window.focus()
                e.target.close()
                // if (VueThis.$route.name !== 'rooms') VueThis.$router.push({ name: 'rooms' })
            }, false)
        },

        playSound () {
            var audio = new Audio(`${this.publicUrl}/message-tone.mp3`)
            audio.play()
                .catch((error) => {
                    console.info(error)
                })
        }
    }
}
</script>
