<template>
    <div>
        <div class="position-relative">
            <!-- Call status -->
            <div class="col-12">
                <div v-if="!connection.error" class="row bg-primary text-white py-1">
                    <div class="col">
                        <small class="d-block">
                            <span class="font-weight-semibold">{{ $t('label.status') }}:</span>
                            <!-- localParticipant status -->
                            <span v-if="localParticipant.state">{{ $t('label.' + localParticipant.state) }}</span>
                            <!-- Spinner -->
                            <span v-if="['preparing_devices', 'connecting', 'reconnecting'].includes(localParticipant.state)"><i class="fas fa-circle-notch fa-spin"></i></span>

                            <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && (!remoteParticipant.state || ['disconnected'].includes(remoteParticipant.state))">
                                <span> - {{ $t('label.waiting_for_patient') }}</span>
                                <span class="font-weight-semibold"> {{ call.patient.id ? call.patient.last_name + ' ' + call.patient.first_name : $t('label.unknown') }}</span>
                                <!-- Spinner -->
                                <span v-if="!remoteParticipant.state || ['reconnecting', 'disconnected'].includes(remoteParticipant.state)"><i class="fas fa-circle-notch fa-spin"></i></span>
                            </span>

                            <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && ['connected', 'reconnected'].includes(remoteParticipant.state)">
                                <span>{{ $t('label.with') }}</span>
                                <span class="font-weight-semibold"> {{ call.patient.id ? call.patient.last_name + ' ' + call.patient.first_name : $t('label.unknown') }}</span>
                            </span>

                            <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && ['reconnecting'].includes(remoteParticipant.state)">
                                <span> - </span>
                                <span class="font-weight-semibold"> {{ call.patient.id ? call.patient.last_name + ' ' + call.patient.first_name : $t('label.unknown') }}</span>
                                <span>{{ $t('label.is_reconnecting') }}</span>
                                <i class="fas fa-circle-notch fa-spin"></i>
                            </span>

                        </small>
                        <!--small class="d-block" v-if="localParticipant.error">{{ localParticipant.error }}</small-->
                    </div>
                </div>
                <div v-else class="row bg-danger text-white py-1">
                    <div class="col-12">
                        <small class="d-block"><span class="font-weight-semibold">Error:</span> {{ connection.error }}</small>
                    </div>
                </div>
            </div>
            <!-- / Call status -->

            <!-- / Video -->
            <div :class="mainTrack === 'local' ? 'primary-video-track' : 'secondary-video-track'">
                <div v-if="mainTrack === 'remote'" class="toggle-main-track" @click="mainTrack = 'local'"></div>
                <span class="local-devices-status">
                    <small v-if="localAudio.error" class="d-block my-1 bg-danger">{{ $t('label.audio_error') }}: {{ localAudio.error }}</small>
                    <small v-if="localVideo.error" class="d-block my-1 bg-danger">{{ $t('label.video_error') }}: {{ localVideo.error }}</small>
                    <ladda-btn v-if="activeRoom && !activeRoom.participants.size"
                        data-style="zoom-out"
                        class="btn btn-sm btn-danger my-2 ml-2"
                        @click.native="unstart.modal = true">{{ $t('label.cancel_request') }}</ladda-btn>
                </span>
                <div id="localTrack"></div>
            </div>

            <div :class="mainTrack === 'remote' ? 'primary-video-track' : 'secondary-video-track'">
                <div v-if="mainTrack === 'local'" class="toggle-main-track" @click="mainTrack = 'remote'"></div>
                <span class="remote-devices-status">
                    <small v-if="remoteAudio.isEnabled === false" class="d-block my-1">{{ $t('label.participant_audio_disabled') }}</small>
                    <small v-if="remoteVideo.isEnabled === false" class="d-block my-1">{{ $t('label.participant_video_disabled') }}</small>
                </span>
                <div id="remoteTrack" :class="{'disabled': !remoteVideo.isEnabled}"></div>
            </div>
            <!-- / Video -->

            <!-- Video actions -->
            <div
                v-if="call.room.id"
                class="video-actions text-white py-1 px-3 d-flex align-items-center">

                <div v-if="room.id" class="participant-name d-block">
                    <div v-if="call.patient.id">
                        <router-link
                            v-if="$auth.check('patient_view')"
                            class="text-small text-primary"
                            :to="{name: 'patients-view', params: { resourceId: call.patient.id }}">
                            {{ call.patient.last_name }} {{ call.patient.first_name }}
                        </router-link>
                        <small class="d-block" v-else>{{ call.patient.last_name }} {{ call.patient.first_name }}</small>
                        <small class="d-block">DNI {{call.patient.doc_number}}</small>
                    </div>
                    <div v-else>
                        <small class="d-block">{{$t('label.unknown')}}</small>
                        <small class="d-block">DNI {{call.room.hints.doc_number}}</small>
                    </div>
                </div>

                <div class="d-flex justify-content-end flex-fill">
                    <div v-if="$root.showFixedVideo">
                        <ladda-btn
                            v-if="!activeRoom"
                            :loading="starting || connection.loading"
                            :disabled="!room.id || !mediaReady || connection.loading"
                            :title="$t('label.start_call')"
                            data-style="zoom-out"
                            class="btn btn-sm btn-primary"
                            @click.native="startRoom">{{ !connection.error ? $t('label.start') : $t('label.retry') }}</ladda-btn>

                        <b-btn
                            :disabled="!mediaReady || localVideo.error !== null"
                            size="sm"
                            type="button"
                            class="btn btn-default ml-2"
                            :title="$t('label.disable_video')"
                            @click="muteVideo">
                            <span class="fa-stack fa-xs">
                                <i class="fas fa-video fa-stack-2x"></i>
                                <i v-if="!localVideo.isEnabled" class="fas fa-slash fa-stack-2x"></i>
                            </span>
                        </b-btn>

                        <b-btn
                            :disabled="!mediaReady || localAudio.error !== null"
                            size="sm"
                            type="button"
                            class="btn btn-default ml-2"
                            :title="$t('label.disable_audio')"
                            @click="muteAudio">
                            <span class="fa-stack fa-xs">
                                <i class="fas fa-microphone fa-stack-2x"></i>
                                <i v-if="!localAudio.isEnabled" class="fas fa-slash fa-stack-2x"></i>
                            </span>
                        </b-btn>

                        <b-dd
                            :disabled="!room.id"
                            size="sm"
                            dropup
                            class="ml-2"
                            variant="danger"
                            :text="$t('label.finish')"
                            :right="true">

                            <b-dd-item @click="finish.modal = true">{{ $t('label.finish') }}</b-dd-item>
                            <b-dd-item v-if="$auth.check('call_reject') && $auth.check('secretary', 'roles')" @click="reject.modal = true">{{ $t('label.reject') }}</b-dd-item>
                            <b-dd-item v-else-if="$auth.check('call_reject') && $auth.check('doctor', 'roles')" @click="reject.modal = true">{{ $t('label.derive_to_secretary') }}</b-dd-item>
                        </b-dd>
                    </div>
                    <div v-else>
                        <router-link
                            :to="{name: 'call'}"
                            v-slot="{ href, navigate}"
                            custom>
                            <b-btn :href="href"
                                @click="navigate"
                                size="sm"
                                variant="default">
                                {{$t('label.view_call')}}
                            </b-btn>
                            </router-link>
                    </div>
                </div>
            </div>
            <!-- / Video actions -->
        </div>

        <!-- Unstart modal -->
        <b-modal v-model="unstart.modal"
            size="md"
            :no-close-on-backdrop="unstart.noClose"
            :no-close-on-esc="unstart.noClose"
            :hide-header-close="unstart.noClose"
            @hidden="Object.assign($data.unstart, $options.data().unstart)">

            <!-- Title -->
            <template #modal-title>
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span>
                <small class="d-block text-muted">{{ $t('room.modal.unstart_kicker') }}</small>
            </template>
            <!-- / Title -->

            <!-- Body -->
            <span>{{ $t('room.modal.unstart_info') }}</span>
            <form-errors-component class="mt-3" :error="unstart.error" v-if="unstart.error"/>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer="{ cancel }">
                <b-button size="sm"
                    variant="secondary"
                    @click="cancel()">{{ $t('label.cancel') }}</b-button>
                <ladda-btn
                    :loading="unstart.loading"
                    data-style="zoom-out"
                    class="btn btn-sm btn-danger"
                    @click.native="unstartRoom">{{ $t('label.cancel_request') }}</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Unstart modal -->

        <!-- Reject modal -->
        <b-modal v-model="reject.modal"
            size="md"
            :no-close-on-backdrop="reject.noClose"
            :no-close-on-esc="reject.noClose"
            :hide-header-close="reject.noClose"
            @hidden="Object.assign($data.reject, $options.data().reject)">

            <!-- Title -->
            <template #modal-title>
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span>
                <small class="d-block text-muted">{{ $t('room.modal.reject_kicker') }}</small>
            </template>
            <!-- / Title -->

            <!-- Body -->
            <span>{{ $t('room.modal.reject_info') }}</span>
            <form-errors-component class="mt-3" :error="reject.error" v-if="reject.error"/>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer="{ cancel }">
                <b-button size="sm"
                    variant="secondary"
                    @click="cancel()">{{ $t('label.cancel') }}</b-button>
                <ladda-btn
                    :loading="reject.loading"
                    data-style="zoom-out"
                    class="btn btn-sm btn-danger"
                    @click.native="rejectRoom">{{ $t('label.reject') }}</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Reject modal -->

        <!-- Finish modal -->
        <b-modal v-model="finish.modal"
            size="md"
            :no-close-on-backdrop="finish.noClose"
            :no-close-on-esc="finish.noClose"
            :hide-header-close="finish.noClose"
            @hidden="Object.assign($data.finish, $options.data().finish)">

            <!-- Title -->
            <template #modal-title>
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span>
                <small class="d-block text-muted">{{ $t('room.modal.finish_kicker') }}</small>
            </template>
            <!-- / Title -->

            <!-- Body -->
            <span>{{ $t('room.modal.finish_info') }}</span>
            <form-errors-component class="mt-3" :error="finish.error" v-if="finish.error"/>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer="{ cancel }">
                <b-button size="sm"
                    variant="secondary"
                    @click="cancel()">{{ $t('label.cancel') }}</b-button>
                <ladda-btn
                    :loading="finish.loading"
                    data-style="zoom-out"
                    class="btn btn-sm btn-danger"
                    @click.native="finishRoom">{{ $t('label.finish') }}</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Finish modal -->
    </div>
</template>

<script>
import { connect, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video'
import { mapState } from 'vuex'

export default {
    name: 'Video',

    data: () => ({
        activeRoom: null,
        starting: false,

        unstart: {
            modal: false,
            loading: false,
            noClose: false,
            success: null,
            error: null
        },

        reject: {
            modal: false,
            loading: false,
            noClose: false,
            success: null,
            error: null
        },

        finish: {
            modal: false,
            loading: false,
            noClose: false,
            success: null,
            error: null
        },

        connection: {
            loading: false,
            success: false,
            error: null
        },

        localParticipant: {
            state: null,
            error: null
        },

        remoteParticipant: {
            state: 'waiting',
            error: null
        },

        // Tracks
        mainTrack: 'local',

        localAudioTrack: {},
        localAudio: {
            isEnabled: false,
            isStarted: false,
            isStopped: false,
            error: null
        },

        localVideoTrack: {},
        localVideo: {
            isEnabled: false,
            isStarted: false,
            isStopped: false,
            error: null
        },

        remoteDataTrack: {},

        remoteAudioTrack: {},
        remoteAudio: {
            isEnabled: true,
            isStarted: false,
            isSwitchedOff: false,
            error: null
        },

        remoteVideoTrack: {},
        remoteVideo: {
            isEnabled: true,
            isStarted: false,
            isSwitchedOff: false,
            error: null
        }
    }),

    mounted () {
        this.$root.$on('stopTracks', () => this.stopTracks())
    },

    created () {
        // window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
        // window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    watch: {
        mediaReady (val) {
            console.log('media ready:' + val)
            if (val && !this.activeRoom && !this.twilioToken) {
                this.localParticipant.state = 'ready_to_start_call'
                this.remoteParticipant.state = 'waiting'
            } else if (val && !this.activeRoom && this.twilioToken && !this.connection.loading) {
                this.connect()
            }
        },

        room: {
            handler (newRoom, oldRoom) {
                // console.log(newRoom, oldRoom)
                if (this.$auth.user() && (newRoom.host_installation_id === this.$auth.user().installation_id || (!newRoom.host_installation_id && newRoom.status === 'derived'))) {
                    if (!this.localVideoTrack.id && !this.localAudioTrack.id) {
                        this.initLocalMedia()
                    }
                } else {
                    if (this.localVideoTrack.id && this.localAudioTrack.id) {
                        this.stopTracks()
                    }
                }
            },
            deep: true
        },

        twilioToken (newToken, oldToken) {
            if (newToken && !oldToken && this.mediaReady) this.connect()
        }
    },

    computed: {
        ...mapState({
            twilioToken: state => state.call.twilioToken,
            call: state => state.call,
            room: state => state.call.room
        }),

        mediaReady: function () {
            return !!this.localVideoTrack.id && !!this.localAudioTrack.id
        }
    },

    destroyed () {
        // console.log('VideoWrapper destroyed')
    },

    methods: {
        beforeWindowUnload (e) {
            // Cancel the event
            e.preventDefault()
            // this.disconnect()
            if (this.activeRoom && ['started', 'attended'].includes(this.room.status)) {
                axios.patch(`/rooms/${this.room.id}/unstart`)
            }
        },

        stopTracks () {
            this.disconnect()

            if (this.localVideoTrack.id) {
                console.log('stopping video track')
                this.localVideoTrack.stop()
                this.localVideoTrack.detach().forEach(function (element) {
                    element.srcObject = null
                    element.remove()
                })
                this.localVideoTrack = {}
            }

            if (this.localAudioTrack.id) {
                console.log('stopping audio track')
                this.localAudioTrack.stop()
                this.localAudioTrack.detach()
                this.localAudioTrack = {}
            }
        },

        initLocalMedia () {
            console.log('starting tracks')
            this.localParticipant.state = 'preparing_devices'

            // Video track
            createLocalVideoTrack({ width: 350 }).then((track) => {
                this.localVideoTrack = track

                const localMediaContainer = document.getElementById('localTrack')
                localMediaContainer.appendChild(track.attach())

                this.localVideo = {
                    isEnabled: track.isEnabled,
                    isStarted: track.isStarted,
                    isStopped: track.isStopped,
                    error: null
                }

                const VueThis = this
                track.on('enabled', function (track) { VueThis.localVideo.isEnabled = track.isEnabled })
                track.on('disabled', function (track) { VueThis.localVideo.isEnabled = track.isEnabled })
                track.on('started', function (track) { VueThis.localVideo.isStarted = track.isStarted })
                track.on('stopped', function (track) { VueThis.localVideo.isStopped = track.isStopped })
            }).catch(e => { this.localVideo.error = e.message })

            // Audio track
            createLocalAudioTrack().then(track => {
                this.localAudioTrack = track

                this.localAudio = {
                    isEnabled: track.isEnabled,
                    isStarted: track.isStarted,
                    isStopped: track.isStopped,
                    error: null
                }

                const VueThis = this
                track.on('enabled', function (track) { VueThis.localAudio.isEnabled = track.isEnabled })
                track.on('disabled', function (track) { VueThis.localAudio.isEnabled = track.isEnabled })
                track.on('started', function (track) { VueThis.localAudio.isStarted = track.isStarted })
                track.on('stopped', function (track) { VueThis.localAudio.isStopped = track.isStopped })
            }).catch(e => { this.localAudio.error = e.message })
        },

        async connect () {
            console.log('connecting')
            this.connection.loading = true
            this.connection.error = null
            this.localParticipant.state = 'connecting'
            this.remoteParticipant.state = null
            this.localParticipant.error = null
            const VueThis = this

            const tracks = []
            if (this.localVideoTrack.id) tracks.push(this.localVideoTrack)
            if (this.localAudioTrack.id) tracks.push(this.localAudioTrack)

            const connectOptions = {
                name: this.room.name,
                audio: true,
                video: { width: 400 },
                tracks: tracks
                // logLevel: 'info'
            }

            await connect(this.call.twilioToken, connectOptions).then(function (room) {
                VueThis.activeRoom = room

                // Remote participant events
                room.participants.forEach(participant => {
                    console.log(participant.identity + ' joined the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantConnected', function (participant) {
                    console.log(participant.identity + ' joined the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantDisconnected', function (participant) {
                    console.log(participant.identity + ' left the Room')
                    if (![...VueThis.activeRoom.participants].length) {
                        VueThis.remoteParticipant.state = participant.state
                        setTimeout(function () { VueThis.mainTrack = 'local' }, 4000)
                    }
                })

                room.on('participantReconnected', participant => {
                    console.log(participant.identity + ' reconnected to the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantReconnecting', participant => {
                    console.log(participant.identity + ' is reconnecting to the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'local' }, 4000)
                })

                // Local participant events
                VueThis.localParticipant.state = room.localParticipant.state

                room.on('reconnected', () => {
                    console.log('Reconnected!')
                    VueThis.localParticipant.state = VueThis.activeRoom.localParticipant.state
                    if ([...VueThis.activeRoom.participants].length) {
                        VueThis.remoteParticipant.state = [...VueThis.activeRoom.participants][0][1].state
                    }
                })

                room.on('trackMessage', (data, track, participant) => {
                    console.log(data)
                })

                room.on('reconnecting', error => {
                    VueThis.localParticipant.state = VueThis.activeRoom.localParticipant.state
                    if (error.code === 53001) {
                        console.log('Reconnecting your signaling connection!', error.message)
                        VueThis.localParticipant.error = error.message
                    } else if (error.code === 53405) {
                        console.log('Reconnecting your media connection!', error.message)
                        VueThis.localParticipant.error = error.message
                    }
                })

                room.on('trackSubscribed', function (track, publication, participant) {
                    if (['audio', 'video'].includes(track.kind)) {
                        const remoteMediaContainer = document.getElementById('remoteTrack')
                        remoteMediaContainer.appendChild(track.attach())

                        if (track.kind === 'audio') {
                            VueThis.remoteAudio.isEnabled = track.isEnabled
                        } else if (track.kind === 'video') {
                            VueThis.remoteVideo.isEnabled = track.isEnabled
                        }

                        track.on('disabled', function (track) {
                            if (track.kind === 'audio') {
                                VueThis.remoteAudio.isEnabled = false
                            } else if (track.kind === 'video') {
                                VueThis.remoteVideo.isEnabled = false
                            }
                        })

                        track.on('enabled', function (track) {
                            if (track.kind === 'audio') {
                                VueThis.remoteAudio.isEnabled = true
                            } else if (track.kind === 'video') {
                                VueThis.remoteVideo.isEnabled = true
                            }
                        })
                    }
                })

                room.on('trackUnsubscribed', function (track, publication, participant) {
                    if (!['data'].includes(track.kind)) {
                        track.detach().forEach(function (mediaElement) {
                            mediaElement.remove()
                        })
                    }
                })

                room.on('disconnected', function (room, error) {
                    VueThis.localParticipant.state = room.localParticipant.state

                    if (error) {
                        console.log('Unexpectedly disconnected:', error.code, error.message)
                        VueThis.localParticipant.error = error.message
                        if ([53405, 53216].includes(error.code)) { VueThis.unstartRoom() }
                    }

                    room.localParticipant.tracks.forEach(function (localTrack) {
                        if (!['data'].includes(localTrack.kind)) {
                            // localTrack.track.stop()
                            // localTrack.track.detach()
                        }
                    })

                    VueThis.resetData()
                })
            }).catch((error) => {
                this.connection.error = error
            })

            this.connection.loading = false
        },

        async disconnect () {
            if (this.activeRoom) {
                await this.activeRoom.disconnect()
                this.resetData()
            }
        },

        muteVideo () {
            this.localVideoTrack.isEnabled ? this.localVideoTrack.disable() : this.localVideoTrack.enable()
        },

        muteAudio () {
            this.localAudioTrack.isEnabled ? this.localAudioTrack.disable() : this.localAudioTrack.enable()
        },

        resetData () {
            this.activeRoom = null

            this.connection = {
                loading: false,
                success: false,
                error: null
            }

            this.localParticipant = {
                state: this.mediaReady ? 'ready_to_start_call' : null,
                error: null
            }

            this.remoteParticipant = {
                state: this.mediaReady ? 'ready_to_start_call' : null,
                error: null
            }

            // Tracks
            this.mainTrack = 'local'

            this.remoteAudio = {
                isEnabled: true,
                isStarted: false,
                isSwitchedOff: false,
                error: null
            }

            this.remoteVideo = {
                isEnabled: true,
                isStarted: false,
                isSwitchedOff: false,
                error: null
            }
        },

        // Start room
        startRoom () {
            this.starting = true

            axios.patch(`/rooms/${this.room.id}/start`)
                .then(response => {
                    this.$store.dispatch('call/setTwilioToken', response.data.twilio_token)
                    this.$store.dispatch('call/setRoom', { ...response.data.data })
                    this.$store.dispatch('rooms/update', { ...response.data.data })
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.starting = false
                })
        },

        // Unstart room
        unstartRoom () {
            this.unstart.loading = true
            this.unstart.noClose = true

            axios.patch(`/rooms/${this.room.id}/unstart`)
                .then(response => {
                    this.$store.dispatch('call/setTwilioToken', null)
                    this.$store.dispatch('call/setRoom', { ...response.data.data })
                    this.$store.dispatch('rooms/update', { ...response.data.data })
                    this.unstart.modal = false

                    this.$notify({
                        group: 'success',
                        title: this.$t('label.success'),
                        text: response.data.message
                    })
                }).catch((error) => {
                    this.unstart.error = error.response
                    this.unstart.loading = false
                    this.unstart.noClose = false
                })
        },

        // Reject room
        rejectRoom () {
            this.reject.loading = true
            this.reject.noClose = true

            axios.patch(`/rooms/${this.room.id}/reject`)
                .then((response) => {
                    this.$store.dispatch('rooms/update', { ...response.data.data })
                    this.$store.dispatch('call/resetState')
                    this.$router.push({ name: 'rooms', params: { mustResetState: true } })
                    this.reject.modal = false

                    this.$notify({
                        group: 'success',
                        title: this.$t('label.success'),
                        text: response.data.message
                    })
                }).catch((error) => {
                    this.reject.error = error.response
                    this.reject.loading = false
                    this.reject.noClose = false
                })
        },

        finishRoom () {
            this.finish.loading = true
            this.finish.noClose = true

            axios.delete(`/rooms/${this.room.id}/finish`)
                .then((response) => {
                    this.$store.dispatch('rooms/remove', response.data.data.id)
                    this.$router.push({ name: 'rooms', params: { mustResetState: true } })
                    this.finish.modal = false

                    this.$notify({
                        group: 'success',
                        title: this.$t('label.success'),
                        text: response.data.message
                    })
                }).catch((error) => {
                    this.finish.error = error.response
                    this.finish.loading = false
                    this.finish.noClose = false
                })
        }
    }
}
</script>
