<template>
    <div class="authentication-wrapper authentication-1 px-4">
        <!--language-switcher/ -->
        <div class="authentication-inner py-5">

        <!-- Logo -->
        <div class="d-flex justify-content-center align-items-center">
            <img :src="`${publicUrl}/img/logo.svg`">
        </div>
        <!-- / Logo -->

        <router-view />

        </div>
    </div>
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>

<script>
// import LanguageSwitcher from '@/components/partials/LanguageSwitcher'

export default {
    name: 'auth-layout',

    components: {
        // 'language-switcher': LanguageSwitcher
    },

    mounted () {
        this.$root.$emit('removeSplashScreen')
    }
}
</script>
