<template>
    <div class="row">
        <div class="col-12" v-if="$auth.user()">
            <span class="text-big mr-2">{{ $t('label.accept_incoming_calls') }}: </span>
            <label class="switcher switcher-sm mt-n1"
                :class="$auth.user().online_status ? 'switcher-success' : 'switcher-danger switcher-danger-websocket'">
                <input
                    v-model="$auth.user().online_status"
                    type="checkbox"
                    class="switcher-input"
                    @click="changeOnlineStatus($event)">
                <span class="switcher-indicator cursor-pointer">
                    <span class="switcher-yes"></span>
                    <span class="switcher-no"></span>
                </span>
            </label>
        </div>

        <!-- ONLINE MODAL -->
        <b-modal v-model="online.modal"
            @hidden="Object.assign($data.online, $options.data().online)">
            <div slot="modal-title">
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span><br>
                <small class="text-muted">{{ $t('auth.modal.set_me_online_kicker') }}</small>
            </div>

            <b-form-row>
                <b-form-group class="col">
                    <span class="d-block">{{ $t('auth.modal.set_me_online_info') }}</span>
                    <span>{{ $t('label.ask_continue') }}</span>
                </b-form-group>
            </b-form-row>

            <form-errors-component :error="online.error" v-if="online.error"/>

            <div slot="modal-footer">
                <b-btn variant="secondary" class="mr-2" @click="online.modal = false">{{ $t('label.cancel') }}</b-btn>
                <ladda-btn
                    :loading="online.loading"
                    data-style="zoom-out"
                    class="btn btn-primary"
                    @click.native="setOnline(false)">{{ $t('label.accept') }}</ladda-btn>
            </div>
        </b-modal>
        <!-- / ONLINE MODAL -->

        <!-- OFFLINE MODAL -->
        <b-modal v-model="offline.modal"
            @hidden="Object.assign($data.offline, $options.data().offline)">
            <div slot="modal-title">
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span><br>
                <small class="text-muted">{{ $t('auth.modal.set_me_offline_kicker') }}</small>
            </div>

            <b-form-row>
                <b-form-group class="col">
                    <span class="d-block">{{ $t('auth.modal.set_me_offline_info') }}</span>
                    <span>{{ $t('label.ask_continue') }}</span>
                </b-form-group>
            </b-form-row>

            <form-errors-component :error="offline.error" v-if="offline.error"/>

            <div slot="modal-footer">
                <b-btn variant="secondary" class="mr-2" @click="offline.modal = false">{{ $t('label.cancel') }}</b-btn>
                <ladda-btn
                    :loading="offline.loading"
                    data-style="zoom-out"
                    class="btn btn-danger"
                    @click.native="setOffline(false)">{{ $t('label.accept') }}</ladda-btn>
            </div>
        </b-modal>
        <!-- / OFFLINE MODAL -->

        <!-- WARNING MODAL -->
        <b-modal v-model="warning.modal"
            @hidden="Object.assign($data.warning, $options.data().warning)">
            <div slot="modal-title">
                {{ $t('label.user') }} <span class="font-weight-light">{{ $t('label.offline') }}</span><br>
                <small class="text-muted">{{ $t('auth.modal.warning_online_kicker') }}</small>
            </div>

            <b-form-row>
                <b-form-group class="col">
                    <span class="d-block">{{ $t('auth.modal.warning_online_info') }}</span>
                </b-form-group>
            </b-form-row>

            <form-errors-component :error="online.error" v-if="online.error"/>

            <div slot="modal-footer">
                <b-btn variant="secondary" class="mr-2" @click="warning.modal = false">{{ $t('label.cancel') }}</b-btn>
                <ladda-btn
                    :loading="online.loading"
                    data-style="zoom-out"
                    class="btn btn-primary"
                    @click.native="setOnline(false)">{{ $t('label.connect') }}</ladda-btn>
            </div>
        </b-modal>
        <!-- / OFFLINE MODAL -->
    </div>
</template>

<script>

export default {
    name: 'ChangeOnlineStatus',

    data: () => ({
        online: {
            modal: false,
            loading: false,
            error: false
        },

        warning: {
            modal: false,
            loading: false,
            error: false
        },

        offline: {
            modal: false,
            loading: false,
            error: false
        }
    }),

    mounted () {
        this.$auth
            .load()
            .then(async () => {
                if (this.$auth.check('profile_set_online') && this.$auth.user().online_status === 0) {
                    this.warning.modal = true
                }
            })
    },

    methods: {
        changeOnlineStatus (evt) {
            evt.preventDefault()
            this.$auth.user().online_status ? this.offline.modal = true : this.online.modal = true
        },

        setOnline (silent = true) {
            this.online.loading = true

            axios.patch('/users/set-online')
                .then((response) => {
                    this.$auth.fetch()
                    this.online.modal = false
                    this.warning.modal = false

                    if (!silent) {
                        this.$notify({
                            group: 'success',
                            title: this.$t('label.success'),
                            text: response.data.message
                        })
                    }
                })
                .catch((e) => {
                    console.log(e)
                    this.online.error = e.response
                })
                .finally(() => {
                    this.online.loading = false
                })
        },

        setOffline (silent = true) {
            this.offline.loading = true

            axios.patch('/users/set-offline')
                .then((response) => {
                    this.$auth.fetch()
                    this.offline.modal = false

                    if (!silent) {
                        this.$notify({
                            group: 'success',
                            title: this.$t('label.success'),
                            text: response.data.message
                        })
                    }
                })
                .catch((e) => {
                    console.log(e)
                    this.offline.error = e.response
                })
                .finally(() => {
                    this.offline.loading = false
                })
        }
    }
}
</script>
