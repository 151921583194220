export default {

    request: function (req, token) {
        this.drivers.http.setHeaders.call(this, req, {
            Authorization: 'Bearer ' + token,
            Accept: 'application/json'
        })
        // Set auth_refresh_token on every request
        this.drivers.http.setHeaders.call(this, req, {
            Refresh: this.token(this.options.refreshTokenKey)
        })
        // Set auth_refresh_token on every request
        this.drivers.http.setHeaders.call(this, req, {
            'x-device-installation-id': localStorage.getItem('x-device-installation-id')
        })
    },

    response: function (res) {
        // if (res.status === 401) this.logout()
        var headers = this.drivers.http.getHeaders.call(this, res)

        // Set auth_refresh_token if available
        var refresh_token = headers.Refresh || headers.refresh
        if (refresh_token) this.token(this.options.refreshTokenKey, refresh_token)

        // Set auth_access_token if available
        var token = headers.Authorization || headers.authorization
        if (token) {
            token = token.split(/Bearer:?\s?/i)
            return token[token.length > 1 ? 1 : 0].trim()
        }
    }
}
