<template>
    <div>
        <div id="app">
            <router-view v-if="$auth.ready() && lang_ready" />
            <notifications group="notifications" position="bottom right"/>
        </div>
    </div>
</template>

<!-- Components -->
<style src="@/vendor/styles/bootstrap-material.scss" lang="scss" class="theme-settings-bootstrap-css"></style>
<style src="@/vendor/styles/appwork-material.scss" lang="scss" class="theme-settings-appwork-css"></style>
<style src="@/vendor/styles/theme-corporate-material.scss" lang="scss" class="theme-settings-theme-css"></style>
<style src="@/vendor/styles/colors-material.scss" lang="scss" class="theme-settings-colors-css"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-flatpickr-component/vue-flatpickr-component.scss" lang="scss"></style>

<!-- Theme -->
<style src="@/style.scss" lang="scss"></style>

<script>

export default {
    name: 'app',

    data: () => ({
        lang_ready: false,
        isMobile: false
    }),

    async created () {
        try {
            if (!this.isIOS()) {
                if (!('Notification' in window)) {
                    console.log('This browser does not support system notifications')
                } else if (Notification !== null && Notification.permission !== 'denied') {
                    Notification.requestPermission()
                }
            }
        } catch (error) {
            console.log('error', error)
        }
        const lang = localStorage.getItem('lang') /* || navigator.language.split('-')[0] */ || process.env.VUE_APP_I18N_LOCALE || 'es'
        this.lang_ready = await this.setLanguage(lang)

        if (this.$isMobile()) {
            this.isMobile = true
            this.removeSplashScreen()
        }
    },

    updated () {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    },

    mounted () {
        this.$root.$on('removeSplashScreen', this.removeSplashScreen)
    },

    methods: {
        removeSplashScreen () {
            // Remove initial splash screen
            const splashScreen = document.querySelector('.app-splash-screen')
            if (splashScreen) {
                splashScreen.style.opacity = 0
                setTimeout(() =>
                    splashScreen && splashScreen.parentNode && splashScreen.parentNode.removeChild(splashScreen)
                , 300)
            }
        },
        isIOS () {
            const browserInfo = navigator.userAgent.toLowerCase()
            if (browserInfo.match('iphone') || browserInfo.match('ipad')) return true
            if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) return true
            return false
        }
    }
}
</script>
