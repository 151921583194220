import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

// Layouts
import Layout from '@/layout/Layout'
import LayoutAuth from '@/layout/LayoutAuth'

//
// import { i18n } from '@/resources/lang/index'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            component: LayoutAuth,
            children: [
                {
                    name: 'login',
                    path: '/',
                    component: () => import('@/components/auth/Login'),
                    meta: {
                        auth: false,
                        title: 'login'
                    }
                },
                {
                    name: 'request-reset-password',
                    path: '/request-reset-password',
                    component: () => import('@/components/auth/ResetPasswordRequest'),
                    meta: {
                        auth: false,
                        title: 'forgot_password'
                    }
                },
                {
                    name: 'reset-password',
                    path: '/reset-password',
                    component: () => import('@/components/auth/ResetPassword'),
                    props: true,
                    meta: {
                        auth: false,
                        title: 'reset_password'
                    }
                },
                {
                    name: 'request-verify-account',
                    path: '/request-verify-account',
                    component: () => import('@/components/auth/VerifyAccountRequest'),
                    meta: {
                        auth: false,
                        title: 'verify_your_email'
                    }
                },
                {
                    name: 'verify-account',
                    path: '/verify-account',
                    component: () => import('@/components/auth/VerifyAccount'),
                    meta: {
                        auth: false,
                        title: 'verify_your_email'
                    }
                }
            ]
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    name: 'dashboard',
                    path: 'dashboard',
                    component: () => import('@/components/statistics/Layout'),
                    meta: {
                        auth: true,
                        title: 'home'
                    }
                },
                /*******************************************************************
                 * Users
                 */
                {
                    name: 'profile-view',
                    path: '/profile',
                    component: () => import('@/components/users/LayoutProfile'),
                    props: (route) => ({
                        editMode: false
                    }),
                    meta: {
                        auth: 'profile_view'
                    }
                },
                {
                    name: 'profile-edit',
                    path: '/profile/edit',
                    component: () => import('@/components/users/LayoutProfile'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'profile_edit'
                    }
                },
                /*******************************************************************
                 * Doctors
                 */
                {
                    name: 'doctors',
                    path: '/doctors',
                    component: () => import('@/components/doctors/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'doctor_view'
                    }
                },
                {
                    name: 'doctors-view',
                    path: '/doctors/view/:resourceId',
                    component: () => import('@/components/doctors/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'doctor_view'
                    }
                },
                {
                    name: 'doctors-edit',
                    path: '/doctors/edit/:resourceId',
                    component: () => import('@/components/doctors/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'doctor_edit'
                    }
                },
                {
                    name: 'doctors-create',
                    path: '/doctors/create',
                    component: () => import('@/components/doctors/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'doctor_create'
                    }
                },
                /*******************************************************************
                 * Secretaries
                 */
                {
                    name: 'secretaries',
                    path: '/secretaries',
                    component: () => import('@/components/secretaries/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'secretary_view'
                    }
                },
                {
                    name: 'secretaries-view',
                    path: '/secretaries/view/:resourceId',
                    component: () => import('@/components/secretaries/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'secretary_view'
                    }
                },
                {
                    name: 'secretaries-edit',
                    path: '/secretaries/edit/:resourceId',
                    component: () => import('@/components/secretaries/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'secretary_edit'
                    }
                },
                {
                    name: 'secretaries-create',
                    path: '/secretaries/create',
                    component: () => import('@/components/secretaries/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'secretary_create'
                    }
                },
                /*******************************************************************
                 * Admins
                 */
                {
                    name: 'admins',
                    path: '/admins',
                    component: () => import('@/components/admins/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'admin_view'
                    }
                },
                {
                    name: 'admins-view',
                    path: '/admins/view/:resourceId',
                    component: () => import('@/components/admins/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'admin_view'
                    }
                },
                {
                    name: 'admins-edit',
                    path: '/admins/edit/:resourceId',
                    component: () => import('@/components/admins/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'admin_edit'
                    }
                },
                {
                    name: 'admins-create',
                    path: '/admins/create',
                    component: () => import('@/components/admins/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'admin_create'
                    }
                },
                /*******************************************************************
                 * Patients
                 */
                {
                    name: 'patients',
                    path: '/patients',
                    component: () => import('@/components/patients/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'patient_view'
                    }
                },
                {
                    name: 'patients-view',
                    path: '/patients/view/:resourceId',
                    component: () => import('@/components/patients/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'patient_view'
                    }
                },
                {
                    name: 'patients-edit',
                    path: '/patients/edit/:resourceId',
                    component: () => import('@/components/patients/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'patient_edit'
                    }
                },
                {
                    name: 'patients-create',
                    path: '/patients/create',
                    component: () => import('@/components/patients/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'patient_create'
                    }
                },

                /*******************************************************************
                 * Call Layout
                 */
                {
                    name: 'call',
                    path: '/call',
                    component: () => import('@/components/call/Layout'),
                    meta: {
                        auth: ['call_view_new', 'call_view_secretary', 'call_view_payment', 'call_view_doctor', 'call_view_finished']
                    }
                },

                /*******************************************************************
                 * Room Layout
                 */
                {
                    name: 'rooms',
                    path: '/rooms',
                    component: () => import('@/components/rooms/Layout/?:mustResetState'),
                    props: true,
                    meta: {
                        auth: ['call_view_new', 'call_view_secretary', 'call_view_payment', 'call_view_doctor', 'call_view_finished']
                    }
                },

                /*******************************************************************
                 * Activity
                 */
                {
                    name: 'activity-patients',
                    path: '/activity/patients',
                    component: () => import('@/components/activity/LayoutPatients'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'profile_view_patients'
                    }
                },
                {
                    name: 'activity-calls',
                    path: '/activity/calls',
                    component: () => import('@/components/activity/LayoutCalls'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'profile_view_calls'
                    }
                },
                {
                    name: 'activity-notes',
                    path: '/activity/notes',
                    component: () => import('@/components/activity/LayoutNotes'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'profile_view_notes'
                    }
                },
                {
                    name: 'activity-prescriptions',
                    path: '/activity/prescriptions',
                    component: () => import('@/components/activity/LayoutPrescriptions'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'profile_view_prescriptions'
                    }
                },
                {
                    name: 'activity-statistics',
                    path: '/activity/statistics',
                    component: () => import('@/components/activity/LayoutStatistics'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'profile_view_statistics'
                    }
                },

                /*******************************************************************
                 * Cities
                 */
                {
                    name: 'cities',
                    path: '/cities',
                    component: () => import('@/components/cities/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'city_view'
                    }
                },
                {
                    name: 'cities-view',
                    path: '/cities/view/:resourceId',
                    component: () => import('@/components/cities/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'city_view'
                    }
                },
                {
                    name: 'cities-edit',
                    path: '/cities/edit/:resourceId',
                    component: () => import('@/components/cities/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'city_edit'
                    }
                },
                {
                    name: 'cities-create',
                    path: '/cities/create',
                    component: () => import('@/components/cities/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'city_create'
                    }
                },

                /*******************************************************************
                 * States
                 */
                {
                    name: 'states',
                    path: '/states',
                    component: () => import('@/components/states/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'state_view'
                    }
                },
                {
                    name: 'states-view',
                    path: '/states/view/:resourceId',
                    component: () => import('@/components/states/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'state_view'
                    }
                },
                {
                    name: 'states-edit',
                    path: '/states/edit/:resourceId',
                    component: () => import('@/components/states/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'state_edit'
                    }
                },
                {
                    name: 'states-create',
                    path: '/states/create',
                    component: () => import('@/components/states/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'state_create'
                    }
                },

                /*******************************************************************
                 * Countries
                 */
                {
                    name: 'countries',
                    path: '/countries',
                    component: () => import('@/components/countries/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'country_view'
                    }
                },
                {
                    name: 'countries-view',
                    path: '/countries/view/:resourceId',
                    component: () => import('@/components/countries/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'country_view'
                    }
                },
                {
                    name: 'countries-edit',
                    path: '/countries/edit/:resourceId',
                    component: () => import('@/components/countries/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'country_edit'
                    }
                },
                {
                    name: 'countries-create',
                    path: '/countries/create',
                    component: () => import('@/components/countries/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'country_create'
                    }
                },

                /*******************************************************************
                 * Agreements
                 */
                {
                    name: 'agreements',
                    path: '/agreements',
                    component: () => import('@/components/agreements/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'agreement_view'
                    }
                },
                {
                    name: 'agreements-view',
                    path: '/agreements/view/:resourceId',
                    component: () => import('@/components/agreements/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'agreement_view'
                    }
                },
                {
                    name: 'agreements-edit',
                    path: '/agreements/edit/:resourceId',
                    component: () => import('@/components/agreements/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'agreement_edit'
                    }
                },
                {
                    name: 'agreements-create',
                    path: '/agreements/create',
                    component: () => import('@/components/agreements/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'agreement_create'
                    }
                },

                /*******************************************************************
                 * Specialities
                 */
                {
                    name: 'specialities',
                    path: '/specialities',
                    component: () => import('@/components/specialities/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'speciality_view'
                    }
                },
                {
                    name: 'specialities-view',
                    path: '/specialities/view/:resourceId',
                    component: () => import('@/components/specialities/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'speciality_view'
                    }
                },
                {
                    name: 'specialities-edit',
                    path: '/specialities/edit/:resourceId',
                    component: () => import('@/components/specialities/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'speciality_edit'
                    }
                },
                {
                    name: 'specialities-create',
                    path: '/specialities/create',
                    component: () => import('@/components/specialities/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'speciality_create'
                    }
                },

                /*******************************************************************
                 * Genders
                 */
                {
                    name: 'genders',
                    path: '/genders',
                    component: () => import('@/components/genders/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'gender_view'
                    }
                },
                {
                    name: 'genders-view',
                    path: '/genders/view/:resourceId',
                    component: () => import('@/components/genders/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'gender_view'
                    }
                },
                {
                    name: 'genders-edit',
                    path: '/genders/edit/:resourceId',
                    component: () => import('@/components/genders/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'gender_edit'
                    }
                },
                {
                    name: 'genders-create',
                    path: '/genders/create',
                    component: () => import('@/components/genders/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'gender_create'
                    }
                },

                /*******************************************************************
                 * Rates
                 */
                {
                    name: 'rates',
                    path: '/rates',
                    component: () => import('@/components/rates/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'rate_view'
                    }
                },
                {
                    name: 'rates-view',
                    path: '/rates/view/:resourceId',
                    component: () => import('@/components/rates/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'rate_view'
                    }
                },
                {
                    name: 'rates-edit',
                    path: '/rates/edit/:resourceId',
                    component: () => import('@/components/rates/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'rate_edit'
                    }
                },
                {
                    name: 'rates-create',
                    path: '/rates/create',
                    component: () => import('@/components/rates/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'rate_create'
                    }
                },

                /*******************************************************************
                 * Doctypes
                 */
                {
                    name: 'doctypes',
                    path: '/doctypes',
                    component: () => import('@/components/doctypes/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'doctype_view'
                    }
                },
                {
                    name: 'doctypes-view',
                    path: '/doctypes/view/:resourceId',
                    component: () => import('@/components/doctypes/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'doctype_view'
                    }
                },
                {
                    name: 'doctypes-edit',
                    path: '/doctypes/edit/:resourceId',
                    component: () => import('@/components/doctypes/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'doctype_edit'
                    }
                },
                {
                    name: 'doctypes-create',
                    path: '/doctypes/create',
                    component: () => import('@/components/doctypes/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'doctype_create'
                    }
                },

                /*******************************************************************
                 * Reasons
                 */
                {
                    name: 'reasons',
                    path: '/reasons',
                    component: () => import('@/components/reasons/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'reason_view'
                    }
                },
                {
                    name: 'reasons-view',
                    path: '/reasons/view/:resourceId',
                    component: () => import('@/components/reasons/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'reason_view'
                    }
                },
                {
                    name: 'reasons-edit',
                    path: '/reasons/edit/:resourceId',
                    component: () => import('@/components/reasons/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'reason_edit'
                    }
                },
                {
                    name: 'reasons-create',
                    path: '/reasons/create',
                    component: () => import('@/components/reasons/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'reason_create'
                    }
                },

                /*******************************************************************
                 * Roles
                 */
                {
                    name: 'roles',
                    path: '/roles',
                    component: () => import('@/components/roles/LayoutIndex'),
                    props: route => ({ query: route.query.q }),
                    meta: {
                        auth: 'role_view'
                    }
                },
                {
                    name: 'roles-view',
                    path: '/roles/view/:resourceId',
                    component: () => import('@/components/roles/Layout'),
                    props: (route) => ({
                        editMode: false,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'role_view'
                    }
                },
                {
                    name: 'roles-edit',
                    path: '/roles/edit/:resourceId',
                    component: () => import('@/components/roles/Layout'),
                    props: (route) => ({
                        editMode: true,
                        resourceId: parseInt(route.params.resourceId)
                    }),
                    meta: {
                        auth: 'role_edit'
                    }
                },
                {
                    name: 'roles-create',
                    path: '/roles/create',
                    component: () => import('@/components/roles/Layout'),
                    props: (route) => ({
                        editMode: true
                    }),
                    meta: {
                        auth: 'role_create'
                    }
                }
            ]
        },
        {
            name: '403',
            path: '/403',
            component: () => import('@/components/errors/NotAuthorized'),
            meta: {
                auth: true
            }
        },
        {
            name: '404',
            path: '*',
            component: () => import('@/components/errors/NotFound')
        }
    ]
})

router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Attach video to floating wrapper if not calls view component
    const videoComponent = document.getElementById('video-component')
    const floatingVideoContainer = document.getElementById('floating-video-wrapper')
    if (floatingVideoContainer) floatingVideoContainer.append(videoComponent)

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add('app-loading')

    // document.title = to.meta.title ? i18n.tc(to.meta.title, 1) + ' | ' + process.env.VUE_APP_TITLE : process.env.VUE_APP_TITLE

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10)
})

export default router
