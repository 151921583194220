// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import store from './store'
import App from './App'
import router from './router'
import _ from 'lodash' // eslint-disable-line no-unused-vars

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

// 3th party libraries import
import axios from 'axios'
import VueAxios from 'vue-axios'
import axiosCancel from 'axios-cancel-plugin'
import moment from 'moment'
import FileSelector from 'vue-file-selector'
import VueI18n from 'vue-i18n'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import VueMobileDetection from 'vue-mobile-detection'

import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverBearer from '@/resources/drivers/bearer.js'
import driverHttpAxios from '@/resources/drivers/axios.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import driverOAuth2Google from '@websanova/vue-auth/dist/drivers/oauth2/google.esm.js'
import driverOAuth2Facebook from '@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/resources/rules/index'

import NProgress from 'vue-nprogress'

// Components import
import LaddaBtn from '@/vendor/libs/ladda/Ladda'
import Notifications from 'vue-notification'
import Multiselect from 'vue-multiselect'
import FormError from '@/components/partials/FormErrors'
import ImageWrapper from '@/components/partials/ImageWrapper'
import flatPickr from 'vue-flatpickr-component'
import ChangesNotSaved from '@/components/partials/ChangesNotSaved'

// Mixins import
import localizationsMixin from '@/mixins/localizations'
import caseConvertMixin from '@/mixins/caseConvert'
import unitsMixin from '@/mixins/units'
import currencyMixin from '@/mixins/currency'

// Libraries init
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers = {
    common: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
    }
}
window.axios = axios
axiosCancel(axios, {
    debug: false // default
})

const options = {
    latencyThreshold: 100, // Number of ms before progressbar starts showing, default: 100,
    router: true, // Show progressbar when navigating routes, default: true
    http: false // Show progressbar when doing Vue.http, default: true
}
const nprogress = new NProgress()

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false
Vue.router = router
// Prototype global vars/functions
Vue.prototype.moment = moment

// Components init
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('LaddaBtn', LaddaBtn)
Vue.component('form-errors-component', FormError)
Vue.component('image-wrapper-component', ImageWrapper)
Vue.component('Multiselect', Multiselect)
Vue.component('ChangesNotSaved', ChangesNotSaved)

// Libraries use
Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(VueAxios, axios)
Vue.use(flatPickr)
Vue.use(NProgress, options)
Vue.use(FileSelector)
Vue.use(VueMobileDetection)
Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        router: Vue.router
    },
    drivers: {
        auth: driverBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook
        }
    },
    options: {
        tokenDefaultKey: 'auth_access_token',
        refreshTokenKey: 'auth_refresh_token',
        rememberkey: 'auth_remember',
        tokenImpersonateKey: 'auth_token_impersonate',
        stores: ['storage', 'cookie'],
        rolesKey: 'permissions',

        loginData: {
            url: '/auth/login',
            method: 'POST',
            redirect: { name: 'dashboard' }
        },

        registerData: {
            url: '/auth/register',
            method: 'POST',
            redirect: false,
            autoLogin: false
        },

        logoutData: {
            url: '/auth/logout',
            method: 'DELETE',
            redirect: { name: 'login' },
            makeRequest: true
        },

        refreshData: {
            url: '/auth/refresh',
            method: 'POST',
            enabled: true,
            interval: 30
        },

        fetchData: {
            url: '/users/info',
            method: 'GET',
            enabled: true
        },

        parseUserData: function (data) {
            return data
        },

        authRedirect: { name: 'login' },
        forbiddenRedirect: { name: '403' },
        notFoundRedirect: { name: 'dashboard' }
    }
})

// Global RTL flag
Vue.mixin({
    data: globals
})

Vue.mixin(localizationsMixin)
Vue.mixin(caseConvertMixin)
Vue.mixin(unitsMixin)
Vue.mixin(currencyMixin)

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {}
})

Vue.prototype.$appName = process.env.VUE_APP_TITLE

new Vue({
    router,
    i18n,
    store,
    nprogress,
    mounted () {
        var uuid = localStorage.getItem('x-device-installation-id')
        if (!uuidValidate(uuid)) {
            localStorage.setItem('x-device-installation-id', uuidv4())
        }
    },
    data: () => ({
        idleDevice: false,
        showFixedVideo: false
    }),
    watch: {
        '$route' (to, from) {
            this.showFixedVideo = to.name === 'call'
        }
    },
    render: h => h(App)
}).$mount('#app')
