<template>
    <div class="callout callout-danger col" v-if="error">
        <div v-if="statusCode === 422">
            <span class="d-block">{{ title }} <b-btn v-if="error.data.details" variant="link" class="p-0" v-b-toggle.details>Detalles</b-btn></span>
            <b-collapse id="details">
                <hr>
                <div v-for="(val, key) in error.data.details" :key="key">
                    <small class="d-block" v-for="(error, index) in val" :key="index">{{ error }}</small>
                </div>
            </b-collapse>
        </div>

        <div v-else-if="statusCode === 404">
            <span class="d-block">{{ title }} <b-btn v-if="error.data.details" variant="link" class="p-0" v-b-toggle.details>Detalles</b-btn></span>
            <b-collapse id="details" v-if="error.data.details">
                <hr>
                {{ error.data.details }}
            </b-collapse>
        </div>

        <div v-else-if="statusCode === 500">
            <span class="d-block">{{ title }} <b-btn v-if="error.data.details" variant="link" class="p-0" v-b-toggle.details>Detalles</b-btn></span>
            <b-collapse id="details" v-if="error.data.details">
                <hr>
                {{ error.data.details }}
            </b-collapse>
        </div>

        <div v-else-if="statusCode === 403">
            <span class="d-block">{{ title }} <b-btn v-if="error.data.details" variant="link" class="p-0" v-b-toggle.details>Detalles</b-btn></span>
            <b-collapse id="details" v-if="error.data.details">
                <hr>
                {{ error.data.details }}
            </b-collapse>
        </div>

        <div v-else>
            <span class="d-block">{{ title }} <b-btn v-if="error.data.details" variant="link" class="p-0" v-b-toggle.details>Detalles</b-btn></span>
            <b-collapse id="details" v-if="error.data.details">
                <hr>
                {{ error.data.details }}
            </b-collapse>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormErrors',

    props: [
        'error'
    ],

    watch: {
        error: function () {
            setTimeout(function () {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
            }, 200)
        }
    },

    computed: {
        statusCode () {
            return this.error ? this.error.status : null
        },

        title () {
            return this.error ? this.error.data.message : null
        },

        availableDetails () {
            return this.error && this.error.data.details && this.error.data.details.length
        }
    }
}
</script>
