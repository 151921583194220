import { localize } from 'vee-validate'

export default {
    methods: {
        async setLanguage (lang = 'es') {
            try {
                await axios.get(process.env.VUE_APP_API_URL + '/lang/' + lang)
                    .then((response) => {
                        this.$i18n.setLocaleMessage(lang, response.data.trans)
                        this.$root.$i18n.locale = lang

                        localStorage.setItem('lang', lang)
                        localStorage.setItem('locales', JSON.stringify(response.data.locales))

                        axios.defaults.headers.common['Accept-Language'] = lang
                        document.querySelector('html').setAttribute('lang', lang)
                    })
                    .finally(() => {
                        return true
                    })

                import('vee-validate/dist/locale/' + lang + '.json').then(locale => {
                    localize(lang, locale)
                })
            } catch (e) {
                console.log('failed to fetch language')
            }

            return true
        }
    }
}
