<template>
    <div class="row">
        <!-- LOGOUT MODAL -->
        <b-modal v-model="logout.modal">
            <div slot="modal-title">
                {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span><br>
                <small class="text-muted">{{ $t('auth.modal.logout_kicker') }}</small>
            </div>

            <b-form-row>
                <b-form-group class="col">
                    <span v-html="$t('auth.modal.logout_info')"></span></b-form-group>
            </b-form-row>

            <div slot="modal-footer">
                <b-btn variant="secondary" class="mr-2" @click="logout.modal = false">{{ $t('label.cancel') }}</b-btn>
                <ladda-btn
                    :loading="logout.loading"
                    data-style="zoom-out"
                    class="btn btn-danger"
                    @click.native="doLogout">{{ $t('label.logout') }}</ladda-btn>
            </div>
        </b-modal>
        <!-- / LOGOUT MODAL -->
    </div>
</template>

<script>

export default {
    name: 'Logout',

    data: () => ({
        logout: {
            modal: false,
            loading: false
        }
    }),

    mounted () {
        this.$root.$on('requestLogout', () => {
            this.logout.modal = true
        })
    },

    methods: {
        doLogout () {
            this.logout.loading = true
            this.$root.$emit('stopTracks')

            this.$auth.logout().then(data => {
                this.$store.dispatch('call/resetState')
                const uuid = localStorage.getItem('x-device-installation-id') // Keep uuid
                localStorage.clear()
                localStorage.setItem('x-device-installation-id', uuid)
                this.$destroy()
                this.$forceUpdate()
            })
        }
    }
}
</script>
