export default {
    methods: {
        camelToUnderscoreKeys (obj) {
            var newObj = _.mapKeys(obj, (value, key) => _.snakeCase(key))
            return newObj
        },

        snakeToCamelKeys (obj) {
            var newObj = _.mapKeys(obj, (value, key) => _.camelCase(key))
            return newObj
        },

        objectToId (obj) {
            Object.keys(obj).forEach((key) => { if (typeof obj[key] === 'object' && obj[key] !== null) obj[key] = obj[key].id })
            return obj
        },

        prepareAxiosRequest (obj) {
            // Convert camelCase
            var newObj = this.camelToUnderscoreKeys(obj)
            // Clear empty objects
            Object.keys(newObj).forEach((key) => (newObj[key] === '' || newObj[key] === undefined) && delete newObj[key])
            return newObj
        }
    }
}
