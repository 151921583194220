import { extend } from 'vee-validate'
import moment from 'moment'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => { extend(rule, rules[rule]) })

/**
 * Valid format date
 * @value the input value string
 * @format the date format to be validated against (IE: DD/MM/YYYY or YYYY-MM-DD)
 * Returns true if valid or false if not
 */
extend('date_format', (value, [format]) => {
    const date = moment(value, format, true)
    return date.isValid()
})
