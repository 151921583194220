<template>
    <sidenav :orientation="orientation" :class="curClasses">
        <!-- Brand demo (see src/demo.css) -->
        <div class="app-brand demo" v-if="orientation !== 'horizontal'">
            <span class="app-brand-logo demo mt-n1">
                <img :src="`${publicUrl}/img/isologo.svg`" class="w-100">
            </span>
            <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{ $appName }}</router-link>
            <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
                <i class="ion ion-md-menu align-middle"></i>
            </a>
        </div>
        <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

        <!-- Inner -->
        <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
            <!-- Dashboard -->
            <sidenav-router-link
                icon="ion ion-md-home"
                to="/dashboard"
                :exact="true">{{ $t('label.home') }}</sidenav-router-link>

            <!-- Calls
            <sidenav-menu
                v-if="$auth.check('call_view_new') ||
                    $auth.check('call_view_secretary') ||
                    $auth.check('call_view_payment') ||
                    $auth.check('call_view_doctor') ||
                    $auth.check('call_view_finished')"
                icon="ion ion-md-call"
                :open="isMenuOpen('/rooms') || isMenuOpen('/call')">

                <template slot="link-text">{{ $t('label.calls') }}</template>

                <sidenav-router-link
                    v-if="
                        $auth.check('call_view_new') ||
                        $auth.check('call_view_secretary') ||
                        $auth.check('call_view_payment') ||
                        $auth.check('call_view_doctor') ||
                        $auth.check('call_view_finished')
                    "
                    :to="{ name: 'rooms'}"
                    :exact-path="'/rooms'">{{ $t('label.all_fp') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="call.room.id"
                    :to="{ name: 'call'}"
                    :exact-path="'/call'">{{ $t('label.in_progress') }}</sidenav-router-link>
            </sidenav-menu> -->

            <!-- Calls -->
            <sidenav-router-link
                v-if="!call.room.id && (
                    $auth.check('call_view_new') ||
                    $auth.check('call_view_secretary') ||
                    $auth.check('call_view_payment') ||
                    $auth.check('call_view_doctor') ||
                    $auth.check('call_view_finished')
                )"
                icon="ion ion-md-call"
                :to="{ name: 'rooms'}"
                :exact-path="'/rooms'">{{ $t('label.calls') }}</sidenav-router-link>

            <!-- Calls -->
            <sidenav-router-link
                v-else-if="call.room.id && (
                    $auth.check('call_view_new') ||
                    $auth.check('call_view_secretary') ||
                    $auth.check('call_view_payment') ||
                    $auth.check('call_view_doctor') ||
                    $auth.check('call_view_finished')
                )"
                icon="ion ion-md-call"
                :to="{ name: 'call'}"
                :exact-path="'/call'">{{ $t('label.calls') }}</sidenav-router-link>

            <!-- Patients -->
            <sidenav-router-link
                v-if="$auth.check('patient_view_all')"
                icon="fas fa-user-injured"
                to="/patients"
                exact>{{ $t('label.patients') }}</sidenav-router-link>

            <!-- Activity -->
            <sidenav-menu
                v-if="$auth.check('profile_view_patients') ||
                    $auth.check('profile_view_calls') ||
                    $auth.check('profile_view_notes') ||
                    $auth.check('profile_view_prescriptions') ||
                    $auth.check('profile_view_statistics')"
                icon="fas fa-chart-line"
                :open="isMenuOpen('/activity')">

                <template slot="link-text">{{ $t('label.my_activity') }}</template>

                <sidenav-router-link
                    v-if="$auth.check('profile_view_patients')"
                    :to="{ name: 'activity-patients'}"
                    :active="
                    isMenuActive('/activity/patients')">{{ $t('label.patients') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('profile_view_calls')"
                    :to="{ name: 'activity-calls'}"
                    :active="
                    isMenuActive('/activity/calls')">{{ $t('label.calls') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('profile_view_notes')"
                    :to="{ name: 'activity-notes'}"
                    :active="
                    isMenuActive('/activity/notes')">{{ $t('label.notes') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('profile_view_prescriptions')"
                    :to="{ name: 'activity-prescriptions'}"
                    :active="
                    isMenuActive('/activity/prescriptions')">{{ $t('label.prescriptions') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('profile_view_statistics')"
                    :to="{ name: 'activity-statistics'}"
                    :active="
                    isMenuActive('/activity/statistics')">{{ $t('label.statistics') }}</sidenav-router-link>
            </sidenav-menu>

            <sidenav-divider />

            <!-- Users -->
            <sidenav-menu
                v-if="$auth.check('admin_view') ||
                    $auth.check('secretary_view') ||
                    $auth.check('doctor_view')
                "
                icon="fas fa-users"
                :open="isMenuOpen('/admins') ||
                    isMenuOpen('/doctors') ||
                    isMenuOpen('/secretaries')">

                <template slot="link-text">{{ $t('label.draft') }}</template>

                <sidenav-router-link
                    v-if="$auth.check('admin_view')"
                    :to="{ name: 'admins'}"
                    :active="
                    isMenuActive('/admins')">{{ $t('label.admins') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('doctor_view')"
                    :to="{ name: 'doctors'}"
                    :active="
                    isMenuActive('/doctors')">{{ $t('label.doctors') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('secretary_view')"
                    :to="{ name: 'secretaries'}"
                    :active="
                    isMenuActive('/secretaries')">{{ $t('label.secretaries') }}</sidenav-router-link>
            </sidenav-menu>

            <!-- Settings -->
            <sidenav-menu
                v-if="$auth.check('city_view') ||
                    $auth.check('state_view') ||
                    $auth.check('country_view') ||
                    $auth.check('agreement_view') ||
                    $auth.check('speciality_view') ||
                    $auth.check('gender_view') ||
                    $auth.check('doctype_view') ||
                    $auth.check('reason_view') ||
                    $auth.check('rate_view')
                "
                icon="fas fa-cog"
                :open="
                    isMenuOpen('/cities') ||
                    isMenuOpen('/states') ||
                    isMenuOpen('/countries') ||
                    isMenuOpen('/agreements') ||
                    isMenuOpen('/specialities') ||
                    isMenuOpen('/genders') ||
                    isMenuOpen('/doctypes') ||
                    isMenuOpen('/reasons') ||
                    isMenuOpen('/rates')
                ">

                <template slot="link-text">{{ $t('label.settings') }}</template>

                <sidenav-router-link
                    v-if="$auth.check('city_view')"
                    :to="{ name: 'cities'}"
                    :active="
                    isMenuActive('/cities')">{{ $t('city.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('state_view')"
                    :to="{ name: 'states'}"
                    :active="
                    isMenuActive('/states')">{{ $t('state.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('country_view')"
                    :to="{ name: 'countries'}"
                    :active="
                    isMenuActive('/countries')">{{ $t('country.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('agreement_view')"
                    :to="{ name: 'agreements'}"
                    :active="
                    isMenuActive('/agreements')">{{ $t('agreement.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('speciality_view')"
                    :to="{ name: 'specialities'}"
                    :active="
                    isMenuActive('/specialities')">{{ $t('speciality.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('gender_view')"
                    :to="{ name: 'genders'}"
                    :active="
                    isMenuActive('/genders')">{{ $t('gender.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('doctype_view')"
                    :to="{ name: 'doctypes'}"
                    :active="
                    isMenuActive('/doctypes')">{{ $t('doctype.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('reason_view')"
                    :to="{ name: 'reasons'}"
                    :active="
                    isMenuActive('/reasons')">{{ $t('reason.label.plural') }}</sidenav-router-link>

                <sidenav-router-link
                    v-if="$auth.check('rate_view')"
                    :to="{ name: 'rates'}"
                    :active="
                    isMenuActive('/rates')">{{ $t('rate.label.plural') }}</sidenav-router-link>
            </sidenav-menu>

            <!-- Security -->
            <sidenav-menu
                v-if="$auth.check('role_view') "
                icon="fas fa-shield-alt"
                :open="
                    isMenuOpen('/roles')
                ">

                <template slot="link-text">{{ $t('label.security') }}</template>

                <sidenav-router-link
                    :to="{ name: 'roles'}"
                    :active="
                    isMenuActive('/roles')">{{ $t('role.label.plural') }}</sidenav-router-link>
            </sidenav-menu>
        </div>

        <small v-show="$auth.check('profile_set_online')" class="system-info bg-dark w-100 px-4 py-3 text-nowrap">
            <ChangeOnlineStatus />
        </small>
    </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import ChangeOnlineStatus from '@/components/partials/ChangeOnlineStatus'
import { mapState } from 'vuex'
// import Firebase from '@/components/partials/Firebase'

export default {
    name: 'app-layout-sidenav',
    components: {
    /* eslint-disable vue/no-unused-components */
        Sidenav,
        SidenavLink,
        SidenavRouterLink,
        SidenavMenu,
        SidenavHeader,
        SidenavBlock,
        SidenavDivider,
        ChangeOnlineStatus
        // 'firebase-component': Firebase
    /* eslint-enable vue/no-unused-components */
    },

    props: {
        orientation: {
            type: String,
            default: 'vertical'
        }
    },

    computed: {
        ...mapState({
            call: state => state.call
        }),

        curClasses () {
            let bg = this.layoutSidenavBg

            if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
                bg = bg
                    .replace(' sidenav-dark', '')
                    .replace(' sidenav-light', '')
                    .replace('-darker', '')
                    .replace('-dark', '')
            }

            return `bg-${bg} ` + (
                this.orientation !== 'horizontal'
                    ? 'layout-sidenav'
                    : 'layout-sidenav-horizontal container-p-x flex-grow-0'
            )
        }
    },

    methods: {
        isMenuActive (url) {
            return this.$route.path.indexOf(url) === 0
        },

        isMenuOpen (url) {
            return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
        },

        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        }
    }
}
</script>
