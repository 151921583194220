<template>
    <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

        <!-- Brand demo (see demo.css) -->
        <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
            <span class="app-brand-logo demo">
                <img :src="`${publicUrl}/img/isologo.svg`" class="w-100">
            </span>
            <span class="app-brand-text demo font-weight-normal ml-2">{{ $appName }}</span>
        </b-navbar-brand>

        <!-- Sidenav toggle (see demo.css) -->
        <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
            <a class="nav-item nav-link px-0 mr-lg-4 cursor-pointer" @click="toggleSidenav">
                <i class="ion ion-md-menu text-large align-middle" />
            </a>
        </b-navbar-nav>

        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2">

            <!-- Search -->
            <!--b-navbar-nav class="align-items-lg-center">
                <label class="nav-item navbar-text navbar-search-box p-0 active">
                <i class="ion ion-ios-search navbar-icon align-middle"></i>
                <span class="navbar-search-input pl-2">
                    <b-input name="search" type="text" class="form-control navbar-text mx-2" :placeholder="$t('placeholder.search') + '...'" style="width:200px" />
                </span>
                </label>
            </b-navbar-nav-->
            <!-- / Search -->

            <b-navbar-nav class="align-items-lg-center ml-auto">
                <!--b-nav-item-dropdown no-caret :right="!isRtlMode" class="demo-navbar-notifications mr-lg-3">
                    <template slot="button-content">
                        <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                        <span class="badge badge-primary badge-dot indicator"></span>
                        <span class="d-lg-none align-middle">&nbsp; Notifications</span>
                    </template>

                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        4 New Notifications
                    </div>
                    <b-list-group flush>
                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <div class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"></div>
                        <div class="media-body line-height-condenced ml-3">
                            <div class="text-body">Login from 192.168.1.1</div>
                            <div class="text-light small mt-1">
                            Aliquam ex eros, imperdiet vulputate hendrerit et.
                            </div>
                            <div class="text-light small mt-1">12h ago</div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <div class="ui-icon ui-icon-sm ion ion-md-person-add bg-info border-0 text-white"></div>
                        <div class="media-body line-height-condenced ml-3">
                            <div class="text-body">You have <strong>4</strong> new followers</div>
                            <div class="text-light small mt-1">
                            Phasellus nunc nisl, posuere cursus pretium nec, dictum vehicula tellus.
                            </div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <div class="ui-icon ui-icon-sm ion ion-md-power bg-danger border-0 text-white"></div>
                        <div class="media-body line-height-condenced ml-3">
                            <div class="text-body">Server restarted</div>
                            <div class="text-light small mt-1">
                            19h ago
                            </div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <div class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"></div>
                        <div class="media-body line-height-condenced ml-3">
                            <div class="text-body">99% server load</div>
                            <div class="text-light small mt-1">
                            Etiam nec fringilla magna. Donec mi metus.
                            </div>
                            <div class="text-light small mt-1">
                            20h ago
                            </div>
                        </div>
                        </b-list-group-item>
                    </b-list-group>

                    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all notifications</a>
                </b-nav-item-dropdown-->

                <!--b-nav-item-dropdown no-caret :right="!isRtlMode" class="demo-navbar-messages mr-lg-3">
                    <template slot="button-content">
                        <i class="ion ion-ios-mail navbar-icon align-middle"></i>
                        <span class="badge badge-primary badge-dot indicator"></span>
                        <span class="d-lg-none align-middle">&nbsp; Messages</span>
                    </template>

                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        4 New Messages
                    </div>
                    <b-list-group flush>
                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <img :src="`${publicUrl}/img/avatars/generic.png`" class="d-block ui-w-40 rounded-circle" alt>
                        <div class="media-body ml-3">
                            <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                            <div class="text-light small mt-1">
                            Mae Gibson &nbsp;·&nbsp; 58m ago
                            </div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <img :src="`${publicUrl}/img/avatars/generic.png`" class="d-block ui-w-40 rounded-circle" alt>
                        <div class="media-body ml-3">
                            <div class="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>
                            <div class="text-light small mt-1">
                            Kenneth Frazier &nbsp;·&nbsp; 1h ago
                            </div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <img :src="`${publicUrl}/img/avatars/generic.png`" class="d-block ui-w-40 rounded-circle" alt>
                        <div class="media-body ml-3">
                            <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                            <div class="text-light small mt-1">
                            Nelle Maxwell &nbsp;·&nbsp; 2h ago
                            </div>
                        </div>
                        </b-list-group-item>

                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
                        <img :src="`${publicUrl}/img/avatars/generic.png`" class="d-block ui-w-40 rounded-circle" alt>
                        <div class="media-body ml-3">
                            <div class="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>
                            <div class="text-light small mt-1">
                            Belle Ross &nbsp;·&nbsp; 5h ago
                            </div>
                        </div>
                        </b-list-group-item>
                    </b-list-group>

                    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all messages</a>
                </b-nav-item-dropdown-->

                <!-- Divider -->
                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

                <!-- User menu -->
                <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user" v-if="$auth.user()">

                    <template slot="button-content">
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                            <img :src="avatar" alt class="d-block ui-w-30 rounded-circle">
                            <div class="px-1 mr-lg-2 ml-2 ml-lg-0">
                                <span class="d-block mb-0 mt-n1">{{ name }}</span>
                                <small class="text-capitalize mt-n1 d-block text-info">{{ $t('label.' + role) }}</small>
                            </div>
                        </span>
                    </template>

                    <!--b-dd-item @click="$router.push({ name: 'account-profile', params: { userId: $auth.user().id } })"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
                    <b-dd-item @click="$router.push({ name: 'account-messages', params: { userId: $auth.user().id } })"><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>
                    <router-link
                        :to="{name: 'users-edit', params:{resource_id: $auth.user().id}}"
                        v-slot="{ href, navigate}">
                        <b-dd-item :href="href" @click="navigate"><i class="ion ion-md-settings text-lightest"></i> &nbsp; {{ $t('label.account') }}</b-dd-item>
                    </router-link>
                    <router-link
                        :to="{name: 'users-edit', params:{resource_id: 2}}"
                        v-slot="{ href, navigate}">
                        <b-dd-item :href="href" @click="navigate"><i class="ion ion-md-settings text-lightest"></i> &nbsp; {{ $t('label.account') }}</b-dd-item>
                    </router-link>

                    <b-dd-divider /-->
                    <router-link
                        v-if="$auth.check('profile_view')"
                        :to="{ name: 'profile-view' }"
                        v-slot="{ href, navigate}" custom>
                        <b-dd-item :href="href" @click="navigate"><i class="fas fa-user text-lightest"></i> &nbsp; {{ $t('label.profile') }}</b-dd-item>
                    </router-link>
                    <b-dd-item @click="$root.$emit('requestLogout')"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; {{ $t('label.logout') }}</b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: 'app-layout-navbar',

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        avatar: function () {
            return this.$auth.user().avatar ? this.$auth.user().avatar + '?access_token=' + this.$auth.token() : this.publicUrl + '/img/avatars/generic.png'
        },

        name: function () {
            return this.$auth.user()?.name
        },

        role: function () {
            return this.$auth.user()?.roles.join()
        }
    },

    mounted () {
        if (this.$auth.remember()) {
            this.$notify({
                group: 'info',
                title: this.$t('label.info'),
                text: this.$auth.remember()
            })
        }
    },

    methods: {
        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        },

        getLayoutNavbarBg () {
            return this.layoutNavbarBg
        }
    }
}
</script>
