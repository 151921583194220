export default {
    methods: {
        // This function is used to detect the actual image type,
        formatCurrency (value) {
            if (typeof value !== 'number') {
                return value
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            return formatter.format(value)
        }
    }
}
