<template>
    <div class="row" v-if="showWarning">
        <div class="col">
            <div class="alert alert-warning">
                <span class="d-block text-big font-weight-semibold">Existe una nueva versión disponible de la aplicación web.</span>
                <span class="d-block">Por favor, <a href="#" @click="refreshApp">vuelva a cargar el sitio</a> y limpie el caché de su navegador en caso de encontrarse con errores.</span>
                <span>Versión actual: {{appVersion}}</span> - <span>Versión disponible: {{latestVersion}}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Version',

    data: () => ({
        showWarning: false,
        appVersion: localStorage.getItem('app_version'),
        latestVersion: process.env.VUE_APP_VERSION
    }),

    mounted () {
        if (!localStorage.getItem('app_version')) {
            localStorage.setItem('app_version', this.latestVersion)
        } else if (this.latestVersion !== this.appVersion) {
            this.showWarning = true
        }
    },

    methods: {
        refreshApp () {
            // Get current data
            const currentStorage = {
                uuid: localStorage.getItem('x-device-installation-id'),
                auth_access_token: localStorage.getItem('auth_access_token'),
                auth_refresh_token: localStorage.getItem('auth_refresh_token'),
                auth_stay_signed_in: localStorage.getItem('auth_stay_signed_in')
            }

            location.reload()

            // Clear data
            localStorage.clear()

            // Set current data
            localStorage.setItem('x-device-installation-id', currentStorage.uuid)
            localStorage.setItem('auth_access_token', currentStorage.auth_access_token)
            localStorage.setItem('auth_refresh_token', currentStorage.auth_refresh_token)
            localStorage.setItem('auth_stay_signed_in', currentStorage.auth_stay_signed_in)
        }
    }
}
</script>
