<template>
    <b-modal
        size="md"
        v-model="show"
        :ok-title="$t('label.accept')"
        :cancel-title="$t('label.cancel')"
        @ok="$emit('success'); show = false">

        <div slot="modal-title">
            {{ $t('label.confirm') }} <span class="font-weight-light">{{ $t('label.action') }}</span><br>
            <small class="text-muted">{{ $t('misc.unsaved_changes_kicker') }}</small>
        </div>

        <b-form-row>
            <b-form-group class="col">{{ $t('misc.unsaved_changes_info') }}</b-form-group>
        </b-form-row>
    </b-modal>
</template>

<script>
export default {
    name: 'ChangesNotSaved',

    data: () => ({
        show: false
    }),

    mounted () {
        this.$root.$on('askCancel', (goBack = true) => {
            this.show = true
        })
    }
}
</script>
